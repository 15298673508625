const $maps = $('.map');

if ($maps.length) {
    require(['./../version', 'scriptjs/dist/script'], (version, $script) => {
        // load google maps
        $script('https://maps.googleapis.com/maps/api/js?key=AIzaSyCmgcpVia06Ql4SmZrd5bupUzEIhJLC2O4', () => {
            // loop over map container
            $maps.each(function (i, container) {
                let $container = $(container),
                    mapOptions = {

                        // if you want to enforce a zoom level ever the placemarks are set
                        // zoom: 16,

                        // add custom styles, you can use this editor to create some: https://snazzymaps.com/editor
                        // styles: [],
                    },
                    map,
                    markers = [],
                    bounds = new google.maps.LatLngBounds();

                // add placemarks to the map
                $container.find('.map__list__item div').each(function (i, item) {
                    let $item = $(item),
                        pinFile = '/assets/img/placemarks/marker.png',
                        position = new google.maps.LatLng($item.data('lat'), $item.data('lng'));

                    markers.push(position);

                    version.default(pinFile).then(function (pin) {
                        let marker = new google.maps.Marker({
                            map,
                            position,
                            title: $item.data('title'),
                            cursor: 'pointer',
                            icon: {
                                size: new google.maps.Size(600, 495),
                                scaledSize: new google.maps.Size(36, 33),
                                origin: new google.maps.Point(0, 0),
                                anchor: new google.maps.Point(24, 43),
                                url: pin,
                            },
                        });

                        marker.addListener('click', () => {
                            $item.find('a')[0].click();
                        });
                    });
                });

                // set default center to map if no one is present
                // NOTE: if no center option is present the map will stay a white/blank box
                if (!mapOptions.center) {
                    mapOptions.center = {
                        lat: markers[0].lat(),
                        lng: markers[0].lng(),
                    };
                }

                // init map
                map = new google.maps.Map($container.find('.map__iframe')[0], mapOptions);

                // add all markers to our map
                markers.forEach(position => { bounds.extend(position); });

                // restore zoom after fit bounds
                if (mapOptions.zoom) {
                    let listener = google.maps.event.addListener(map, 'bounds_changed', function () {
                        map.setZoom(mapOptions.zoom);
                        google.maps.event.removeListener(listener);
                    });
                }

                // set the bounds based on the markers
                map.fitBounds(bounds);
            });
        });
    });
}
