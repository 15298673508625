if ($('form').length) {
    require([
        '@spinnwerk/form-helper/prevent-mobile-zoom',
        '@spinnwerk/form-helper/loading-indicator',
        '@spinnwerk/form-helper/select-all',
        '@spinnwerk/form-helper/file-input',
        '@spinnwerk/form-helper/floating-label',
        './multiupload',
        './validation',
    ], (preventMobileZoom, loadingIndicator, selectAll, fileInput, floatingLabel) => {
        function initFormHelpers() {
            // prevent mobile zoom in
            preventMobileZoom.default();

            // automatically attach an loading indicator to submit buttons on form submit
            loadingIndicator.default();

            // enable an (de)select all radiobutton and checkboxes possibility
            selectAll.default();

            // style advanced file inputs
            fileInput.default({
                multibleFilesLabel: ':anz Dateien ausgewählt',
            });

            // init floating-label-inputs
            floatingLabel.default();

            // remove file on icon click and trigger change event
            $('.input--file__input__icon').off('click').on('click', function () {
                $(this).closest('.input--file')
                    .find('input[type="file"]').val('')
                    .trigger('change');
            });
        }

        initFormHelpers();

        // woocommerce replaces DOM completeley, so all functionality must be applied again (-> missing event handlers)
        $(document.body).on('updated_wc_div', () => initFormHelpers());
    });
}
