// ////////////////////////////////////////////////////////////
// register service worker in production mode
if (process.env.NODE_ENV === 'production') {
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
            navigator.serviceWorker.register('/service-worker.js');
        });
    }
}

// ////////////////////////////////////////////////////////////
// bugsnag
import bugsnag from 'bugsnag-js';
if (CONFIG.bugsnag) {
    window.bugsnagClient = bugsnag({
        apiKey: CONFIG.bugsnag,
        collectUserIp: false,
        releaseStage: CONFIG.env,
        notifyReleaseStages: ['production', 'prod', 'stage'],
    });
}

// ////////////////////////////////////////////////////////////
// import needed polyfills
import 'babel-polyfill';

// ////////////////////////////////////////////////////////////
// optimized font loading
import fontFaceLoader from '@spinnwerk/font-face-loader';
fontFaceLoader({
    fonts: ['Burlington'],
});

// ////////////////////////////////////////////////////////////
// variables
const body = document.body,
    $menuButton = $('.menu-toggle'),
    $scrollUp = $('.scroll-up'),
    $sideNav = $('.section-nav'),
    classes = {
        inView: 'js-is-inview',
        sideNavEvaded: 'section-nav--evaded',
    };

let ignoreOnBeforeUnload = false,
    offCanvasActive = 0;

// ////////////////////////////////////////////////////////////
// import version from './lib/version';
import './_includes/foundation';
import inView from 'in-view';

// ////////////////////////////////////////////////////////////
// init and config GA
if (window.dataLayer) {
    require(['./lib/analytics/cf7']);
}

// init facebook pixel
if (CONFIG.fb_pixel_code) {
    require(['./lib/analytics/fbp'], ({ default: FBP }) => {
        FBP.init();
    });
}

// ////////////////////////////////////////////////////////////
// init foundation

// show focus style only after a tab keystroke
function handleFirstTab(event) {
    if (event.keyCode === 9) {
        body.classList.add('user-is-tabbing');
        window.removeEventListener('keydown', handleFirstTab);
    }
}

window.addEventListener('keydown', handleFirstTab);

// fade out on page leave
$('a[href^="mailto"], a[href^="tel"], a[download], .download, a[href^="javascript:"]').on('click', () => {
    ignoreOnBeforeUnload = true;
});

window.addEventListener('beforeunload', () => {
    if (!ignoreOnBeforeUnload) {
        body.classList.add('body--fade-out');
    }

    ignoreOnBeforeUnload = false;
});

$(document)
    .foundation()
    .on('opened.zf.offcanvas', (event) => {
        let $target = jQuery(event.target);

        offCanvasActive++;

        body.classList.add('is-off-canvas-open');
        body.classList.add('is-off-canvas-' + $target.data('transition') + '-' + $target.data('position'));

        // only for our main menu off-canvas
        if (event.target.id === 'off-canvas') {
            $menuButton.addClass('is-active');
        } else {
            $menuButton.addClass('is-disabled');
        }
    })
    .on('closed.zf.offcanvas', (event) => {
        let $target = jQuery(event.target);

        offCanvasActive--;

        if (offCanvasActive === 0) {
            body.classList.remove('is-off-canvas-open');
        }

        body.classList.remove('is-off-canvas-' + $target.data('transition') + '-' + $target.data('position'));

        // only for our main menu off-canvas
        if (event.target.id === 'off-canvas') {
            $menuButton.removeClass('is-active');
        } else {
            $menuButton.removeClass('is-disabled');
        }
    });

// scroll top
$(window)
    .on('scroll', () => {
        let scrollTop = Math.max(document.documentElement.scrollTop, document.body.scrollTop);

        if (scrollTop >= 400 && !$scrollUp.data('isVisible')) {
            $scrollUp.parent().removeClass('hide');
            $scrollUp.fadeIn('fast');
        } else if (scrollTop < 400) {
            $scrollUp
                .data('isVisible', false)
                .stop()
                .fadeOut('fast', () => {
                    // only hide the parent, when the scroll-up-link is in the section-nav
                    if ($scrollUp.parent().is('.section-nav__link')) {
                        $scrollUp.parent().addClass('hide');
                    }
                });
        }
    });

// anchor scrolling
if (CONFIG.scrollFromHashChanges !== true) {
    new Foundation.SmoothScroll($('a[href*="#"]:not([href="#"]):not(.no-scroll)'));
} else {
    $('a[href*="#"]:not([href="#"]):not(.no-scroll)').on('click', event => {
        event.preventDefault(); // do not allow setting the hash, as some browser move directly to the target, before the onhashchange event is triggered

        let $target = $(event.target).closest('[href]'),
            href = $target.attr('href');

        // get the actual hash of the href
        if (href && href.indexOf('#') !== -1) {
            href = href.split('#').pop();
        }

        // mask the hash so that browsers don't navigate
        if (href && href[0] !== '/') {
            href = '/' + href;
        }

        location.hash = href; // set hash explicitly to trigger the onhashchange event, on which scrolling depends
    });
}

// you can listen to breakpoint changes like so:
// $(window).on('changed.zf.mediaquery', (event, current, old) => {
//    console.log(`Breakpoint changed from "${old}" to "${current}"`);
// });

// ////////////////////////////////////////////////////////////
// manage section-nav visibility
inView('.footer')
    .on('enter', () => {
        $sideNav.addClass(classes.sideNavEvaded);
    })
    .on('exit', () => {
        $sideNav.removeClass(classes.sideNavEvaded);
    });

// ////////////////////////////////////////////////////////////
// sliding header
require(['./lib/headroom'], ({ default: Headroom }) => {
    let hr = new Headroom;

    setTimeout(function () {
        hr.show();
    }, 4000);
});

// ////////////////////////////////////////////////////////////
// form helpers
import './lib/form/form';

// ////////////////////////////////////////////////////////////
// masonry
import './lib/layouts/masonry';

// ////////////////////////////////////////////////////////////
// swiper
import './lib/layouts/swiper';

// ////////////////////////////////////////////////////////////
// map
import './lib/layouts/map';

// ////////////////////////////////////////////////////////////
// filter
import './lib/filter';

// ////////////////////////////////////////////////////////////
// update url based on scrolling
require(['./lib/section-nav'], ({ default: SectionNav }) => {
    new SectionNav({
        scrollFromHashChanges: CONFIG.scrollFromHashChanges || false,
        getHash(el) {
            return '/' + (el.getAttribute('data-section-nav-anchor') || el.id);
        },
        getActiveStateSelector(hash) {
            return `[href="#${hash.replace(/^#?\//, '')}"]`;
        },
        getAttribute(hash) {
            return hash.replace(/#\//, '');
        },
    });
});

// ////////////////////////////////////////////////////////////
// browser alerts (async)
require(['./lib/alerts/old-browser'], (oldBrowserAlert) => {
    oldBrowserAlert.default();
});

import 'magnific-popup';

$('div.media-element--img').click(function () {
    let imgsrc = $(this).find('img').attr('data-objectfit');

    $(this).magnificPopup({
        preloader: true,
        items: {
            src: imgsrc,
            type: 'image',
        },
    }).magnificPopup('open');
});

$('div.media-element--video').click(function () {
    let videosrc = $(this).attr('data-video-url');

    $(this).magnificPopup({
        preloader: true,
        items: {
            src: videosrc,
        },
        type: 'iframe',
        iframe: {
            markup: '<div class="mfp-iframe-scaler">' + '<div class="mfp-close"></div>' + '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' + '</div>',
            patterns: {
                youtube: {
                    index: 'youtube.com/',
                    id: 'v=',
                    src: 'https://www.youtube-nocookie.com/embed/%id%?autoplay=1&rel=0',
                },
            },
            srcAction: 'iframe_src',
        },
    }).magnificPopup('open');
});
